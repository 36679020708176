import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";
import Dialog from "@mui/material/Dialog";

const CustomDialog = styled(Dialog)(({ theme, thin }) => ({
  "& .MuiDialogContent-root": {
    textAlign: thin ? "" : "center",
    padding: 0,
    overflow: "hidden",
  },
  "& .MuiPaper-root": {
    borderRadius: "15px",
    padding: thin ? "25px 15px" : "36px 35px",
  },
  "& .MuiDialogActions-root": {
    padding: 0,
    display: "flex",
    justifyContent: "center",
  },
  [theme.breakpoints.up("sm")]: {
    "& .MuiPaper-root": {
      borderRadius: "15px",
      padding: thin ? "25px 15px" : "36px 97px",
    },
  },
}));

export { CustomDialog };
