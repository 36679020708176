import { useForm } from "react-hook-form";
import { useTranslation } from "next-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const useReportForm = () => {
  const { t } = useTranslation("common");
  const schema = yup
    .object({
      reason: yup
        .object()
        .required(t("please_select_option"))
        .typeError(t("please_select_option")),
      //   note: yup.string().required(t("enter_note")),
    })
    .required();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
  });

  return {
    register,
    handleSubmit,
    errors,
    control,
    setValue,
    clearErrors,
    isSubmitting,
  };
};

export default useReportForm;
