import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { reportOptions } from "../../../../utils/utils";
import CustomInputField from "../../../common/FormFields/CustomInputField/CustomInputField";
import useMakeReport from "../../../PropertyCard/components/Actions/hooks/useMakeReport";
import { RedButton } from "../../../common/Buttons/Buttons";
import { CustomAutoComplete } from "../../../AdvancedSearchHeader/components/AdvancedSearch/style";
import { CloseButtonWrapper, CloseIcon } from "./style";
import { FormHelperText } from "@mui/material";
import useReportForm from "./hooks/useReportForm";

const Report = ({ id, setOpen, type }) => {
  const { t } = useTranslation("common");
  const ReportStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [reports, setReport] = useState(null);
  const [reportData, setReportData] = useState(null);

  const [note, setNote] = useState(null);

  const {
    register,
    handleSubmit,
    errors,
    control,
    setValue,
    isSubmitting,
    setError,
    clearErrors,
  } = useReportForm();

  useEffect(() => {
    setNote("note");
  }, [note]);

  const {
    mutate: report,
    isLoading,
    isError,
    error,
  } = useMakeReport({ reportData, setOpen, type });

  const onSubmit = (data) => {
    setReportData(data);
    report(
      { id, reports, note: data?.note }
      // {
      //   onSuccess: () => {
      //     toast.success(t("reported_succesfully"), toastOptions);
      //     setOpen(false);
      //   },
      // }
    );
  };
  const options = _.map(reportOptions, (rep) => {
    return { value: rep?.value, label: t(rep?.label) };
  });
  return (
    <Box xs={{ position: "relative" }}>
      <CloseButtonWrapper
        onClick={() => {
          setOpen(false);
        }}
      >
        <CloseIcon />
      </CloseButtonWrapper>
      <InputLabel
        sx={{
          fontSize: "25px",
          mb: "20px",
          color: "text.main",
          textAlign: "center",
        }}
      >
        {type == 0 ? t("report_the_property") : t("report_the_project")}
      </InputLabel>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputLabel sx={{ fontSize: "15px", mb: "9px", color: "text.main" }}>
          {t("options")}
        </InputLabel>
        <CustomAutoComplete
          name="reason"
          key="reason"
          disablePortal
          id="combo-box-reason"
          options={options}
          sx={{
            width: "100%",
            mb: "17px",
          }}
          size="small"
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={
                !reportOptions ? t("loading") : t("select_your_reason")
              }
              error={errors?.reason?.message}
            />
          )}
          noOptionsText={t("no_options")}
          onChange={(event, value) => {
            setReport(value?.value);
            setValue("reason", value);
            clearErrors("reason");
          }}
        />
        {errors?.reason && (
          <FormHelperText sx={{ color: "red.main", mt: "-15px", mb: "10px" }}>
            {errors?.reason?.message}
          </FormHelperText>
        )}
        <CustomInputField
          name="note"
          label={t("note")}
          control={control}
          size="small"
          fullWidth
          placeholder={t("tell_us_more")}
          multiline
          rows={3}
        />
        <Box
          sx={{ my: "20px" }}
          display="flex"
          alignItems="center"
          justifyContent="center"
        ></Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <RedButton
            type={"submit"}
            color={"primary"}
            disabled={isLoading}
            variant="contained"
            wider
            //startIcon=
          >
            {isLoading && (
              <CircularProgress
                size={18}
                sx={{ mr: "10px", color: "white.main" }}
              />
            )}
            {t("report")}
          </RedButton>
        </Box>
      </form>
    </Box>
  );
};

export default Report;
