import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { FormHelperText } from "@mui/material";
import { CloseButtonWrapper, CloseIcon } from "../ProfileForm/style";
import { CustomButton } from "../common/Buttons/Buttons";
import useMakePublishAndUnpublish from "./hooks/useMakePublishAndUnpublish";
import useDelete from "./hooks/useDelete";
import useMakeRentOrSold from "./hooks/useMakeAsRentOrSold";

const PublishUnpublishPopup = ({ setConfirmOpen, data, setOpen }) => {
  const { t } = useTranslation("common");
  const type = data?.isPublished;
  const id = data?.id;
  const { mutate: publishUnPublish, isLoading: MarkAsLoading } =
    useMakePublishAndUnpublish({ type });

  const handelActions = ({ type, id }) => {
    {
      publishUnPublish(
        { id },
        {
          onSuccess: () => {
            setConfirmOpen(false);
            setOpen(false);
          },
        }
      );
    }
  };

  return (
    <Box xs={{ position: "relative" }}>
      <CloseButtonWrapper
        onClick={() => {
          setConfirmOpen(false);
        }}
      >
        <CloseIcon />
      </CloseButtonWrapper>
      <InputLabel
        sx={{
          fontSize: "25px",
          mb: "20px",
          color: "text.main",
          textAlign: "center",
        }}
      >
        {type == true ? t("publish_property") : t("un_publish_property")}
      </InputLabel>
      <InputLabel
        sx={{
          fontSize: "25px",
          mb: "20px",
          color: "text.main",
          textAlign: "center",
        }}
      >
        {type == true ? t("are_you_sure_publish") : t("are_you_sure_unpublish")}
      </InputLabel>
      <CustomButton
        variant="contained"
        wider
        onClick={() => {
          setConfirmOpen(false);
          setOpen(false);
        }}
      >
        {t("cancel")}
      </CustomButton>{" "}
      <CustomButton
        variant="contained"
        wider
        onClick={() => {
          handelActions({ type, id });
        }}
      >
        {MarkAsLoading ? (
          <CircularProgress size={15} sx={{ mr: "5px" }} color="white" />
        ) : type == true ? (
          t("yes_unpublish")
        ) : (
          t("yes_publish")
        )}
      </CustomButton>
    </Box>
  );
};

export default PublishUnpublishPopup;
