import { useTranslation } from "next-i18next";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useAxios } from "../../../../../hooks/axios";
import { toastOptions } from "../../../../../utils/utils";

//report a property
const useMakeReport = ({ reportData, setOpen, type }) => {
  const { t } = useTranslation("common");
  const listType =
    type == 0 ? "listings" : type == 1 ? "project" : "projectmodel";
  const report = type == 0 ? "reportlisting" : "report";

  const axios = useAxios();
  const queryClient = useQueryClient();
  return useMutation(
    async (values) => {
      const { data } = await axios.post(
        `/reactions/v1/${listType}/${values?.id}/${report}`,
        {
          Reason: values?.reports,
          Note: values?.note,
        }
      );

      return data;
    },

    {
      onSuccess: (data) => {
        toast.success(t("reported_succesfully"), toastOptions);
        setOpen(false);
        queryClient.invalidateQueries(["properties"]);
        queryClient.invalidateQueries(["activity-log", "list"]);

        let likedItem = queryClient.getQueryData([
          "property",
          "detail",
          data?.data?.listingId,
        ]);
        likedItem.data.isLiked = true;
        queryClient.setQueryData(
          ["property", "detail", data?.data?.listingId],
          likedItem
        );
      },
    }
  );
};

export default useMakeReport;
