import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { DialogContent, FormHelperText, IconButton } from "@mui/material";
import {
  CloseButtonWrapper,
  CloseIcon,
  DeleteIcon,
} from "../ProfileForm/style";
import {
  ColouredButton,
  CustomButton,
  GradientButton,
} from "../common/Buttons/Buttons";
import useMakePublishAndUnpublish from "./hooks/useMakePublishAndUnpublish";
import useDelete from "./hooks/useDelete";
import useMakeRentOrSold from "./hooks/useMakeAsRentOrSold";
import PublishUnpublishPopup from "./PublishUnpublishPopup";
import { CustomDialog } from "../common/PopConfirm/style";
import DeletePopup from "./DeletePopup";
import { useRouter } from "next/router";
import {
  Action,
  Actions,
  Header,
  HeaderIconWrapper,
  HeaderInfoWrapper,
  InfoLines,
} from "./style";
import Image from "next/image";
import Stack from "@mui/system/Stack";
import PopUpConfirmation from "../PopUpConfirmation/PopUpConfirmation";
import {
  CustomEditIcon,
  IconWrapper,
} from "../PropertyCard/components/Actions/style";

const ListingManagement = ({ setOpen, data }) => {
  const { t } = useTranslation("common");
  const router = useRouter();
  const [anchorEl, setAnchorEl] = useState(null);
  const [popConfirmVisible, setPopConfirmVisible] = useState(false);
  const [popConfirmVisible1, setPopConfirmVisible1] = useState(false);
  const [popConfirmVisible2, setPopConfirmVisible2] = useState(false);

  const type = data?.isPublished;
  const id = data?.id;
  const listingType = data?.listingType;
  const { mutate: soldOrRent, isLoading: soldOrRentLoading } =
    useMakeRentOrSold({
      listingType,
    });
  const markSoldRent = ({ id }) => {
    soldOrRent(
      { id },
      {
        onSuccess: () => {
          setPopConfirmVisible(false);
          setOpen(false);
        },
      }
    );
  };

  const { mutate: publishUnPublish, isLoading: MarkAsLoading } =
    useMakePublishAndUnpublish({ type });

  const handelActions = ({ type, id }) => {
    {
      publishUnPublish(
        { id },
        {
          onSuccess: () => {
            setPopConfirmVisible(false);
            setOpen(false);
          },
        }
      );
    }
  };

  const { mutate: makeDelete, isLoading: MarkAsDeleteLoading } = useDelete();

  const handelActionsDelete = ({ id }) => {
    {
      makeDelete(
        { id },
        {
          onSuccess: () => {
            setPopConfirmVisible(false);
            setOpen(false);
          },
        }
      );
    }
  };

  const deleteButtons = [
    <Action key={"cancel"}>
      <ColouredButton
        color="#fff"
        fluid
        onClick={() => {
          setPopConfirmVisible(false);
        }}
      >
        {t("cancel")}
      </ColouredButton>
    </Action>,
    <Action key={"delete"}>
      <ColouredButton
        color="#E23636"
        fluid
        onClick={() => {
          handelActionsDelete({ id });
        }}
      >
        {MarkAsDeleteLoading ? (
          <>
            <CircularProgress size={15} sx={{ mr: "5px" }} color="white" />
            {t("yes_delete")}
          </>
        ) : (
          t("yes_delete")
        )}
      </ColouredButton>
    </Action>,
  ];
  const publishButton = [
    <Action key={"cancel-1"}>
      <ColouredButton
        color="#fff"
        fluid
        onClick={() => {
          setPopConfirmVisible1(false);
        }}
      >
        {t("cancel")}
      </ColouredButton>
    </Action>,
    <Action key={"publish"}>
      <ColouredButton
        color="#00bead"
        fluid
        onClick={() => {
          handelActions({ type, id });
        }}
      >
        {MarkAsLoading ? (
          <>
            <CircularProgress size={15} sx={{ mr: "5px" }} color="white" />
            {type == true ? t("yes_unpublish") : t("yes_publish")}
          </>
        ) : type == true ? (
          t("yes_unpublish")
        ) : (
          t("yes_publish")
        )}
      </ColouredButton>
    </Action>,
  ];

  const makeSoldRentedButton = [
    <Action key={"cancel-1"}>
      <ColouredButton
        color="#fff"
        fluid
        onClick={() => {
          markSoldRent({ id });
        }}
      >
        {soldOrRentLoading ? (
          <>
            <CircularProgress size={15} sx={{ mr: "5px" }} color="white" />
          </>
        ) : t("sanadak")}
      </ColouredButton>
    </Action>,
    <Action key={"publish"}>
      <ColouredButton
        color="#fff"
        fluid
        onClick={() => {
          markSoldRent({ id });
        }}
      >
        {soldOrRentLoading ? (
          <>
            <CircularProgress size={15} sx={{ mr: "5px" }} color="white" />
          </>
        ) : t("other")}
      </ColouredButton>
    </Action>,
  ];

  return (
    <Box xs={{ position: "relative" }}>
      <CloseButtonWrapper
        onClick={() => {
          setOpen(false);
        }}
      >
        <IconButton>
          <CloseIcon />
        </IconButton>
      </CloseButtonWrapper>
      <Header>
        <HeaderIconWrapper>
          <Image
            width={48}
            height={48}
            src={require("./icons/eye-icon.svg")}
            alt="Eye icon"
          />
        </HeaderIconWrapper>
        <HeaderInfoWrapper>
          {data?.listingStatusText == "Removed" ? (
            <Typography variant="body2" sx={{ mb: "8px" }}>
              {t("content_violation")}
            </Typography>
          ) : (
            <Typography variant="body2" sx={{ mb: "8px" }}>
              {t("property-type-sale-rent", {
                district: data?.district,
                propertyType: t(data?.propertyType),
                listingType: t(data?.listingType),
              })}
            </Typography>
          )}
          <InfoLines>
            <Typography variant="body1" size="smaller" sx={{ mr: "5px" }}>
              {t("status")}
            </Typography>
            <Typography variant="body2" size="smaller">
              {data?.listingStatusText}
            </Typography>
          </InfoLines>
          <InfoLines>
            <Typography variant="body1" size="smaller" sx={{ mr: "5px" }}>
              {t("ad_num")}
            </Typography>
            <Typography variant="body2" size="smaller">
              {data?.adNumber}
            </Typography>
          </InfoLines>
          <InfoLines>
            {data?.listingStatusText == "Removed" && (
              <Typography variant="body2" size="smaller">
                {t("we_have_remove_your_post")}
              </Typography>
            )}
          </InfoLines>
        </HeaderInfoWrapper>
      </Header>
      {(data?.canMarkAsSold || data?.canMarkAsRented) && (
        <>
          <ColouredButton
            color="#00bead"
            fluid
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
              setPopConfirmVisible2(true);
            }}
          >
            {t("mark_as_sold_or_rent")}
          </ColouredButton>
          <PopUpConfirmation
            open={popConfirmVisible2}
            button={makeSoldRentedButton}
            anchorEl={anchorEl}
            icon={
              <Image
                width={48}
                height={48}
                alt="Eye icon"
                src={require("./icons/eye-icon.svg")}
              />
            }
            title={t("mark_as_sold_or_rent")}
            //subTitle={t("are_you_sure_sold_rented", {type: listingType})}
            onClose={() => {
              setAnchorEl(null);
              setPopConfirmVisible2(false);
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          />
        </>
      )}
      <Actions>
        <Action fluid>
          {data?.canPublish == true && (
            <ColouredButton
              color="#00bead"
              fluid
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setPopConfirmVisible1(true);
              }}
            >
              {t("publish")}
            </ColouredButton>
          )}
        </Action>
        <Action>
          {data?.canEdit == true && (
            <ColouredButton
              color="#fff"
              fluid
              onClick={(e) => {
                router.push(`/edit-property/${id}`);
                setOpen(false);
              }}
            >
              <Stack spacing={1} direction="row" alignItems="center">
                <Image
                  width={14}
                  height={14}
                  src={require("./icons/edit-icon.svg")}
                  alt="Edit icon"
                />
                <Typography variant="body1">{t("edit")}</Typography>
              </Stack>
            </ColouredButton>
          )}
        </Action>
        <Action>
          <div>
            {data?.canDelete == true ? (
              <ColouredButton
                color="#E23636"
                fluid
                onClick={(e) => {
                  setAnchorEl(e.currentTarget);
                  setPopConfirmVisible(true);
                }}
              >
                <Stack spacing={1} direction="row" alignItems="center">
                  <Image
                    width={16}
                    height={16}
                    src={require("./icons/delete-icon.svg")}
                    alt="delete icon"
                  />
                  <Typography variant="body1" sx={{ color: "#fff" }}>
                    {t("delete")}
                  </Typography>
                </Stack>
              </ColouredButton>
            ) : data?.canUnPublish ? 
              <ColouredButton
                color="#E23636"
                fluid
                onClick={(e) => {
                  setAnchorEl(e.currentTarget);
                  setPopConfirmVisible1(true);
                }}
              >
                <Stack spacing={1} direction="row" alignItems="center">
                  <Image
                    width={16}
                    height={16}
                    src={require("./icons/cross-icon.svg")}
                    alt="unpublish icon"
                  />
                  <Typography variant="body1" sx={{ color: "#fff" }}>
                    {t("unpublish")}
                  </Typography>
                </Stack>
              </ColouredButton>
            : (
              data?.listingStatusText != "Removed" && (
                <ColouredButton
                  color="#fff"
                  fluid
                  onClick={(e) => {
                    // setAnchorEl(e.currentTarget);
                    // setPopConfirmVisible(true);
                    setOpen(false);
                  }}
                >
                  {t("cancel")}
                </ColouredButton>
              )
            )}
            <PopUpConfirmation
              open={popConfirmVisible}
              button={deleteButtons}
              anchorEl={anchorEl}
              icon={
                <Image
                  width={48}
                  height={48}
                  alt="Delete icon"
                  src={require("./icons/delete-vector.svg")}
                />
              }
              title={t("delete_property")}
              subTitle={t("are_you_sure_delete")}
              onClose={() => {
                setAnchorEl(null);
                setPopConfirmVisible(false);
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            />
            <PopUpConfirmation
              open={popConfirmVisible1}
              button={publishButton}
              anchorEl={anchorEl}
              icon={
                <Image
                  width={48}
                  height={48}
                  alt="Eye icon"
                  src={require("./icons/eye-icon.svg")}
                />
              }
              title={
                type == false ? t("publish_property") : t("unpublish_property")
              }
              subTitle={
                type == false
                  ? t("are_you_sure_publish")
                  : t("are_you_sure_unpublish")
              }
              onClose={() => {
                setAnchorEl(null);
                setPopConfirmVisible1(false);
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            />
          </div>
        </Action>
      </Actions>
      {/* <InputLabel
        sx={{
          fontSize: "20px",
          mb: "20px",
          color: "text.main",
          textAlign: "center",
        }}
      >
        {t("property-type-sale-rent", {
          district: data?.district,
          propertyType: t(data?.propertyType),
          listingType: t(data?.listingType),
        })}
      </InputLabel>
      <InputLabel
        sx={{
          fontSize: "15px",
          mb: "20px",
          color: "text.main",
          textAlign: "center",
        }}
      >
        {t("ad_num", {
          num: data?.adNumber,
        })}
      </InputLabel>{" "}
      <InputLabel
        sx={{
          fontSize: "15px",
          mb: "20px",
          color: "text.main",
          textAlign: "center",
        }}
      >
        {t("status", {
          status: data?.listingStatusText,
        })}
      </InputLabel>
      <CustomButton
        variant="contained"
        wider
        onClick={() => {
          {
            type == true ? markSoldRent({ id }) : setConfirmOpen(true);
          }
        }}
      >
        {soldOrRentLoading ? (
          <CircularProgress size={15} sx={{ mr: "5px" }} color="white" />
        ) : type == true ? (
          t("mark_as_sold_or_rent")
        ) : (
          t("publish")
        )}
      </CustomButton>{" "}
      <CustomButton
        variant="contained"
        wider
        onClick={() => {
          router.push(`/edit-property/${id}`);
          setOpen(false);
        }}
      >
        {t("edit")}
      </CustomButton>{" "}
      <CustomButton
        variant="contained"
        wider
        onClick={() => {
          {
            type == true ? setConfirmOpen(true) : setDeleteOpen(true);
          }
        }}
      >
        {type == true ? t("unpublish") : t("delete")}
      </CustomButton>
      <CustomDialog open={confirmOpen} fullWidth={true} maxWidth="sm">
        <DialogContent>
          <PublishUnpublishPopup
            setConfirmOpen={setConfirmOpen}
            data={data}
            setOpen={setOpen}
          />
        </DialogContent>
      </CustomDialog>
      <CustomDialog open={deleteOpen} fullWidth={true} maxWidth="sm">
        <DialogContent>
          <DeletePopup
            setDeleteOpen={setDeleteOpen}
            data={data}
            setOpen={setOpen}
          />
        </DialogContent>
      </CustomDialog> */}
    </Box>
  );
};

export default ListingManagement;
