import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";

const Header = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  marginBottom: theme.typography.pxToRem(50),
}));

const HeaderIconWrapper = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  marginRight: "14px",
}));

const HeaderInfoWrapper = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  flexGrow: 1,
}));

const InfoLines = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  display: "flex",
  marginBottom: "5px",
}));

const Actions = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  display: "flex",
  margin: "-1%",
  flexWrap: "wrap",
}));

const Action = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "fluid",
})(({ theme, fluid }) => ({
  margin: "1%",
  width: fluid ? "98%" : "48%",
  position: "relative",
}));

const ListingSearchWrapper = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  display: "flex",
  marginBottom: "25px",
  flexDirection: "column-reverse",
  alignItems: "flex-end",
  [theme.breakpoints.up("sm")]: {
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "flex-start",
    marginBottom: "50px",
  },
}));

const SearchAreaWrapper = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  maxWidth: "670px",
  border: "1px solid #F1F1F1",
  borderRadius: "15px",
  height: "38px",
  padding: "0 10px 0 5px",
}));

const SortWrapper = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  flexShrink: 0,
  minWidth: "160px",
  marginBottom: "15px",
  [theme.breakpoints.up("sm")]: {
    marginLeft: "15px",
  },
  "& .MuiAutocomplete-root": {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "5px",
    margin: "0",
    "& fieldset": {
      padding: "0px !important",
      borderColor: "transparent",
    },
    "&:hover fieldset": {
      borderColor: "transparent",
    },
    "&.Mui-focused fieldset": {
      borderColor: "transparent",
    },
    "& .MuiAutocomplete-input": {
      padding: "2px 5px !important",
    },
    "&.MuiFormControl-root p": {
      display: "none !important",
    },
    "& .MuiInputBase-root": {
      color: "#fff",
    },
    "& .MuiInputBase-root input::placeholder": {
      color: "#fff",
      opacity: 1,
    },
    "& .MuiSvgIcon-root": {
      color: "#fff",
    },
  },
}));

const SearchFiledWrapper = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  flexGrow: 1,
  marginTop: "-4px",
  "& .MuiOutlinedInput-root": {
    height: "32px",
    padding: "0px  !important",
    "& fieldset": {
      padding: "0px !important",
      borderColor: "transparent",
    },
    "&:hover fieldset": {
      borderColor: "transparent",
    },
    "&.Mui-focused fieldset": {
      borderColor: "transparent",
    },
    "& .MuiAutocomplete-input": {
      padding: "0px !important",
    },
  },
}));

export {
  Header,
  HeaderIconWrapper,
  HeaderInfoWrapper,
  InfoLines,
  Actions,
  Action,
  ListingSearchWrapper,
  SearchAreaWrapper,
  SortWrapper,
  SearchFiledWrapper,
};
