import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";
import { FaHeart } from "react-icons/fa";

const ProfileCardStyled = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  borderRadius: '8px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  backgroundColor: '#ffffff',
  height:'100%'
}));

const HeartFilledRedIcon = styled(FaHeart, {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  fontSize: "24px",
  color: theme.palette.red.main,
}));

export { ProfileCardStyled, HeartFilledRedIcon };
