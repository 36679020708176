import React from "react";
import { Controller } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import { CustomInputFieldStyled } from "./style";
import Typography from "@mui/material/Typography";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormHelperText from "@mui/material/FormHelperText";
import { useTranslation } from "next-i18next";

function CustomInputField({
  name,
  label,
  control,
  helperText,
  startAdornment,
  placeholder,
  value,
  ...others
}) {
  const { t } = useTranslation("common");
  return (
    <CustomInputFieldStyled>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <FormControl {...others}>
            <Typography
              variant="body3"
              size="smaller"
              sx={{ mb: "7px", color: "darkGray.main" }}
            >
              {t(label)}
            </Typography>
            <OutlinedInput
              startAdornment={startAdornment}
              placeholder={placeholder}
              {...field}
              {...others}
            />
            <FormHelperText>{helperText}</FormHelperText>
          </FormControl>
        )}
      />
    </CustomInputFieldStyled>
  );
}

export default CustomInputField;
