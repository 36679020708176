import { useMutation } from "react-query";
import { useTranslation } from "next-i18next";
import { useAxios } from "./axios";
import { toast } from "react-toastify";
import { useRouter } from "next/router";

const useCreateChat = () => {
  const axios = useAxios();
  const { t } = useTranslation("common");
  const router = useRouter();

  return useMutation(
    async (chatUserId) => {
      const { data } = await axios.post(`/chat/v1/chats/`, {
        type: 1, //1-OnetoOne,2-Groupchat,
        oneToOneChatSettings: {
          userId: chatUserId,
          //"0a509203-d68b-443d-97c6-aef5db73bb53",
        },
      });
      return data;
    },
    {
      onSuccess: (data) => {
        router.push("/messages");
        // toast.success(t("otp_verified"), toastOptions);
      },
      onError: (err, data) => {
        toast.error(err?.response?.data?.error?.message);
      },
    }
  );
};

export default useCreateChat;
