import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { FormHelperText } from "@mui/material";
import { CloseButtonWrapper, CloseIcon } from "../ProfileForm/style";
import { CustomButton } from "../common/Buttons/Buttons";
import useMakePublishAndUnpublish from "./hooks/useMakePublishAndUnpublish";
import useDelete from "./hooks/useDelete";
import useMakeRentOrSold from "./hooks/useMakeAsRentOrSold";

const DeletePopup = ({ setDeleteOpen, data, setOpen }) => {
  const { t } = useTranslation("common");
  const type = data?.isPublished;
  const id = data?.id;
  const { mutate: makeDelete, isLoading: MarkAsDeleteLoading } = useDelete();

  const handelActions = ({ id }) => {
    {
      makeDelete(
        { id },
        {
          onSuccess: () => {
            setDeleteOpen(false);
            setOpen(false);
          },
        }
      );
    }
  };

  return (
    <Box xs={{ position: "relative" }}>
      <CloseButtonWrapper
        onClick={() => {
          setDeleteOpen(false);
        }}
      >
        <CloseIcon />
      </CloseButtonWrapper>
      <InputLabel
        sx={{
          fontSize: "25px",
          mb: "20px",
          color: "text.main",
          textAlign: "center",
        }}
      >
        {t("delete_property")}
      </InputLabel>
      <InputLabel
        sx={{
          fontSize: "15px",
          mb: "20px",
          color: "text.main",
          textAlign: "center",
        }}
      >
        {t("property-type-sale-rent", {
          district: data?.district,
          propertyType: t(data?.propertyType),
          listingType: t(data?.listingType),
        })}
      </InputLabel>
      <InputLabel
        sx={{
          fontSize: "15px",
          mb: "20px",
          color: "text.main",
          textAlign: "center",
        }}
      >
        {t("ad_num", {
          num: data?.adNumber,
        })}
      </InputLabel>
      <CustomButton
        variant="contained"
        wider
        onClick={() => {
          setDeleteOpen(false);
          setOpen(false);
        }}
      >
        {t("cancel")}
      </CustomButton>

      <CustomButton
        variant="contained"
        wider
        onClick={() => {
          handelActions({ type, id });
        }}
      >
        {MarkAsDeleteLoading ? (
          <CircularProgress size={15} sx={{ mr: "5px" }} color="white" />
        ) : (
          t("yes_delete")
        )}
      </CustomButton>
    </Box>
  );
};

export default DeletePopup;
