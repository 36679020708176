import { useTranslation } from "next-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useAxios } from "../../../hooks/axios";

const useDelete = () => {
  const axios = useAxios();
  const queryClient = useQueryClient();
  const { t } = useTranslation("common");

  return useMutation(
    async (values) => {
      const { data } = await axios.delete(
        `/content/v1/admin/${values?.id}/listings`
      );
      return data;
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["listing", "management"]);
        toast.success(data?.message);
      },
      onError: (error) => {
        toast.success(error?.message);
      },
    }
  );
};
export default useDelete;
