import React from "react";
import Popover from "@mui/material/Popover";
import {
  Action,
  Actions,
  Header,
  HeaderIconWrapper,
  HeaderInfoWrapper,
  PopUpConfirmationStyled,
} from "./style";
import { useTranslation } from "next-i18next";
import { ColouredButton } from "../common/Buttons/Buttons";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";

const PopUpConfirmation = ({ title, button, subTitle, icon, ...others }) => {
  const { t } = useTranslation("common");
  return (
    <PopUpConfirmationStyled>
      <Popover {...others} className="confirmation_popover">
        <Box sx={{ minWidth: "300px", p: "15px" }}>
          <Header>
            <HeaderIconWrapper>{icon}</HeaderIconWrapper>
            <HeaderInfoWrapper>
              <Typography variant="body2" sx={{ mb: "8px" }}>
                {title}
              </Typography>
              <Typography variant="body1" size="smaller" sx={{ mr: "5px" }}>
                {subTitle}
              </Typography>
            </HeaderInfoWrapper>
          </Header>
          <Actions>{button}</Actions>
        </Box>
      </Popover>
    </PopUpConfirmationStyled>
  );
};

export default PopUpConfirmation;
