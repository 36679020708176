import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";

const UserInfoStyled = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexShrink: 0,
}));

const Avatar = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "large",
})(({ theme, large }) => ({
  height: large ? "48px" : "34px",
  width: large ? "48px" : "34px",
  borderRadius: "50%",
  overflow: "hidden",
  position: "relative",
  marginRight: theme.typography.pxToRem(15),
  boxShadow: "0px 0px 8px 1px rgba(0,0,0,0.2)",
}));

const UserVerified = styled("img", {
  shouldForwardProp: (prop) => isPropValid(prop) !== "large",
})(({ theme, large }) => ({
  position: "absolute",
  height: "14px",
  width: "14px",
  right: large ? "12px" : "12px",
  bottom: 0,
  zIndex: 2,
}));

const Info = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  width:'100%'
}));

export { UserInfoStyled, Avatar, UserVerified, Info };
