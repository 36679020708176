import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";
import { AiFillCamera, AiOutlineClose } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";

const ProfileFormStyled = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  margin: `${theme.typography.pxToRem(45)} 0`,
}));

const UploadButtonWrapper = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  position: "absolute",
  bottom: 0,
  right: 0,
  cursor: "pointer",
}));

const CameraIcon = styled(AiFillCamera, {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  color: theme.palette.darkGray.main,
  fontSize: "28px",
}));

const DeleteButtonWrapper = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  position: "absolute",
  bottom: "15px",
  right: 0,
  [theme.breakpoints.up("md")]: {
    bottom: "-60px",
  },
}));

const DeleteIcon = styled(RiDeleteBin6Line, {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  color: theme.palette.red.main,
  fontSize: "20px",
  marginRight: "10px",
}));

const CloseIcon = styled(AiOutlineClose, {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  fontSize: "20px",
}));

const CloseButtonWrapper = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  position: "absolute",
  top: 2,
  right: 2,
  zIndex: 99,
}));

const DobWrapper = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  "& .profile-dob input::placeholder": {
    textTransform: "upperCase",
  },
}));

const NoFlipInputWrapper = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  // "& .phone-input-noflip": {
  direction: theme.direction == "rtl" ? "rtl" : "ltr",
  textAlign: "left",
  // },
}));

export {
  UploadButtonWrapper,
  CameraIcon,
  DeleteButtonWrapper,
  DeleteIcon,
  CloseButtonWrapper,
  CloseIcon,
  DobWrapper,
  NoFlipInputWrapper,
  ProfileFormStyled,
};
