import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";
import Dialog from "@mui/material/Dialog";
import { MdOutlineContentCopy } from "react-icons/md";
import { fontSize } from "@mui/system";
import {
  CallIcon,
  EditIcon,
  FavIcon,
  FavIconFill,
  MessageIcon,
  ReportIcon,
  ShareIcon,
} from "./icons";

const ActionsStyled = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  position: "absolute",
  top: 0,
  bottom: 0,
  padding: `0 ${theme.typography.pxToRem(6)}`,
  zIndex: 99,
  right: 0,
  minWidth: "56px",
}));

const CopyIcon = styled(MdOutlineContentCopy, {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "copied",
})(({ theme, copied }) => ({
  color: copied ? theme.palette.primary.main : "#cccccc",
  fontSize: "20px",
}));

const CustomCallIcon = styled(CallIcon, {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({}) => ({}));

const CustomMessageIcon = styled(MessageIcon, {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({}) => ({}));

const CustomShareIcon = styled(ShareIcon, {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({}) => ({}));

const CustomEditIcon = styled(EditIcon, {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({}) => ({}));

const CustomReportIcon = styled(ReportIcon, {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({}) => ({}));

const CustomFavIcon = styled(FavIcon, {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({}) => ({}));

const CustomFavIconFill = styled(FavIconFill, {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({}) => ({}));

const CustomDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: "15px",
    // padding: "5px 5px",
  },
  [theme.breakpoints.up("sm")]: {
    "& .MuiPaper-root": {
      // padding: "30px 30px",
    },
  },
  [theme.breakpoints.up("md")]: {
    "& .MuiPaper-root": {
      // padding: "80px 130px 25px 130px",
    },
  },
}));

const CopyIconWrapper = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "copied",
})(({ theme, copied }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  width: "44px",
  height: "40px",
  border: "1px solid #cccccc",
  borderRadius: "4px",
  transition: "transform 0.3s ease",
  marginLeft: theme.direction == "rtl" ? "4px" : "4px",
  "&:hover": {
    transform: "translateY(-4px)",
  },
}));

const IconWrapper = styled("div", {
  shouldForwardProp: (prop) =>
    isPropValid(prop) && prop !== "fill" && prop !== "small",
})(({ fill, small }) => ({
  "& svg": {
    color: fill ? "#E23636" : "#fff",
    filter: "drop-shadow(1px 4px 8px #000)",
    width: small ? "27px" : "29px",
    height: small ? "27px" : "29px",
    scale: "0.8",
  },
}));

export {
  ActionsStyled,
  CustomDialog,
  CopyIcon,
  CopyIconWrapper,
  CustomCallIcon,
  IconWrapper,
  CustomShareIcon,
  CustomMessageIcon,
  CustomEditIcon,
  CustomReportIcon,
  CustomFavIcon,
  CustomFavIconFill,
};
