import { useTranslation } from "next-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useAxios } from "../../../hooks/axios";

const useMakeRentOrSold = ({ listingType }) => {
  const axios = useAxios();
  const queryClient = useQueryClient();
  const { t } = useTranslation("common");
  const typeOf = listingType == "Sale" ? "markassold" : "markasrented";
  return useMutation(
    async (values) => {
      const { data } = await axios.post(
        `/content/v1/admin/listing/${values?.id}/${typeOf}`
      );
      return data;
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["listing", "management"]);
        toast.success(data?.message);
      },
      onError: (error) => {
        toast.success(error?.message);
      },
    }
  );
};
export default useMakeRentOrSold;
