import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";
import { AiOutlineClose } from "react-icons/ai";
import Dialog from "@mui/material/Dialog";

const CloseIcon = styled(AiOutlineClose, {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  fontSize: "20px",
}));

const CloseButtonWrapper = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  position: "absolute",
  top: "10px",
  right: "10px",
  cursor: "pointer",
  zIndex: 99,
}));

const CustomDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: "15px",
    // padding: "5px 5px",
  },
  [theme.breakpoints.up("sm")]: {
    "& .MuiPaper-root": {
      // padding: "30px 30px",
    },
  },
  [theme.breakpoints.up("md")]: {
    "& .MuiPaper-root": {
      // padding: "80px 130px 25px 130px",
    },
  },
}));

export { CloseButtonWrapper, CloseIcon, CustomDialog };
