import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";

const PopUpConfirmationStyled = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: "aliceblue !important",
  },
}));

const Header = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  marginBottom: theme.typography.pxToRem(20),
}));

const HeaderIconWrapper = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  marginRight: "14px",
}));

const HeaderInfoWrapper = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  flexGrow: 1,
}));

const InfoLines = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  display: "flex",
  marginBottom: "5px",
}));

const Actions = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  display: "flex",
  margin: "-1%",
  flexWrap: "wrap",
}));

const Action = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "fluid",
})(({ theme, fluid }) => ({
  margin: "1%",
  width: fluid ? "98%" : "48%",
  position: "relative",
}));

export {
  PopUpConfirmationStyled,
  Header,
  HeaderIconWrapper,
  HeaderInfoWrapper,
  Action,
  Actions,
};
